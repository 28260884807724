import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://cf6338bd2037c99d104ae4b1f4c6ab4f@o4508278564519936.ingest.de.sentry.io/4508278567010384",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});